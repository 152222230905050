import { getApiClient } from '../../services/apiClient'
import { getTokenData } from './helper'
import { closedAreaContent } from './content'

export const getUserData = async (token: string) => {
  const tokenData: any = getTokenData(token)
  const roles = []

  if (tokenData.fanclub_status === 2) {
    roles.push(closedAreaContent.user.roles.api.member)
  }

  let memberId = null
  let representative = undefined

  const apiClient = getApiClient(token)
  try {
    const memberInfo = await apiClient.getMember()
    if (memberInfo) {
      if (memberInfo.id > 0) {
        memberId = memberInfo.id
      }
      if (memberInfo.representative) {
        roles.push(closedAreaContent.user.roles.api.representative)
        representative = await apiClient.getRepresentative({ id: memberId })
      }
    }
  } catch (error) {
    console.error('Ignore error while fetching member info', error)
  }

  return {
    id: memberId,
    firstname: tokenData.given_name,
    lastname: tokenData.family_name,
    name: tokenData.name,
    email: tokenData.email,
    region: tokenData.region,
    token,
    roles,
    representative,
    crmAccount: tokenData.crmAccount,
  }
}

export const USER_ACTIONS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
}
