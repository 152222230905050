export const closedAreaContent = {
  user: {
    roles: {
      fanclub_member: 'Fanclub Mitglied',
      fanclub_representative: 'Fanclub Betreuer',
      press: 'Medienvertreter',
      api: {
        member: 'fanclub_member',
        representative: 'fanclub_representative',
      },
    },
  },
  status: {
    loading: 'Seite lädt...',
    login: 'Bitte logge Dich ein.',
    noData: 'Es sind keine Einträge vorhanden.',
    error: {
      representative: 'Fehler beim Laden der Fanclub Betreuer',
      user: 'Bitte melden Sie sich (erneut) an.',
      error: 'Es ist ein interner Fehler aufgetreten. Bitte versuche es später erneut.',
      unavailableFile:
        'Eine oder mehrere Anhänge sind nicht verfügbar, da die hochgeladenen Dateien möglicherweise einen Virus enthalten. Bitte lösche die markierten Dateien aus der Liste.',
    },
  },
  backlink: {
    label: 'Zurück zum Dashboard',
    path: '/app/dashboard/',
    defaultText: 'Zurück',
  },
  dashboard: {
    stage: {
      logout: {
        href: `${process.env.OIDC_AUTH_URL}realms/${process.env.OIDC_REALM}/protocol/openid-connect/logout?redirect_uri=${process.env.OIDC_LOGOUT_REDIRECT_URL}`,
        label: 'logout',
      },
      bonus: {
        href: '/bonus/',
        label: 'Zu den Bonuspunkten',
      },
    },
    heroTeaser: {
      kicker: 'Fan Club',
      image: [
        {
          uid: 31415926,
          src: `${process.env.DFB_DAM_URL}/301056-fanclub-hero.jpg`,
          alternative: 'Fan Club Mitglieder auf einer Tribüne bei einem Fußballspiel',
          extension: 'jpg',
        },
      ],
      images: [
        {
          image_headline: 'Anmeldung zum Fan Club',
          image_alternative: 'Jetzt mitmachen!',
          image_link_obj: {
            url: 'https://fanclub-dienste.dfb.de/app/anmeldung/einzelkarte',
            type: 'page',
          },
        },
      ],
    },
  },
  representative: {
    stage: {
      label: 'Betreuer Übersicht',
      headline: 'Finde deinen Betreuer',
    },
    intro_text:
      'Die ehrenamtlich tätigen Fan Club Betreuer stammen aus den verschiedensten Regionen Deutschlands und sind die Bindeglieder zwischen dem Orga-Team des Fan Club und den Mitgliedern. Das macht sie zum ersten Ansprechpartner für Mitglieder aus ihrer Region in allen Fragen bezüglich des Fan Club Nationalmannschaft.<br /><br />Du kannst von hier aus die Profile der Betreuer ansehen und dich einem oder mehreren Betreuern zuordnen. Hierdurch bekommst du künftig Rundschreiben des/ der Betreuer(s). Du kannst deine/ n Betreuer jederzeit wechseln.',
    heads: [
      { label: 'Betreuer', column: 'carer' },
      { label: 'Region/Mannschaft', column: 'region' },
      { label: 'Betreuer-Auswahl', column: 'status' },
    ],
    fallback: 'Es ist ein Fehler beim Laden der Betreuer und Betreuerinnen aufgetreten.',
    link: 'Profil ansehen',
    status: {
      assigned: 'Du bist diesem Betreuer zugeordnet.',
    },
  },
  journeys: {
    stage: {
      label: 'Fan Club Reisen',
      headline: 'Alle Fan Club Reisen',
    },
    intro_text: 'Hier hast Du einen Überblick über alle bevorstehenden und alle vergangenen Reisen der letzten 12 Monate.',
    heads: [
      { label: 'Reise', column: 'journey' },
      { label: 'Reisezeit', column: 'travelling-time' },
      { label: 'Betreuer', column: 'carer' },
      { label: 'Infos', column: 'infos' },
      { label: 'Link', column: 'links' },
    ],
    content: {
      representative: {
        label: 'Betreut durch:',
      },
      date: {
        from: 'Von',
        to: 'Bis',
      },
      costs: 'Kosten',
      departure: 'Abfahrt ab:',
    },
    buttons: {
      create: {
        label: 'Neue Reise erstellen',
        href: '/app/fanclub/reisen/neue-reise',
        icon: 'plus',
      },
      infos: {
        label: 'Alle Infos',
        href: '/app/fanclub/reisen',
      },
      delete: {
        label: 'Reise löschen',
      },
      edit: {
        label: 'Reise bearbeiten',
        href: '/app/fanclub/reisen',
      },
    },
    fallback: 'Du hast noch keine Reisen angelegt.',
    detail: {
      stage: {
        label: 'Fan Club Reisen',
        date: {
          from: 'Von',
          to: 'bis',
        },
        represented_by: 'Betreut durch',
        buttons: {
          edit: 'Reise bearbeiten',
          delete: 'Reise löschen',
        },
      },
      facts: {
        headline: 'Weitere Fakten',
        costs: 'Kosten',
        departure: 'Abfahrt ab',
        hotel: 'Hotel',
        registration: 'Anmeldeschluss',
      },
      signup: {
        headline: 'Anmeldung über E-Mail',
      },
      downloads: {
        headline: 'Downloads',
        downloadLinkText: 'Downloaden',
      },
    },
    preview: {
      headline: 'Vorschau',
      close: 'Schließen',
    },
  },
  circulars: {
    stage: {
      label: 'Fan Club Rundschreiben',
      headline: 'Deine Rundschreiben',
    },
    intro_headline: 'Übersicht über Deine Rundschreiben',
    intro_text:
      'Du kannst deine gesendeten und deine nicht gesendeten Rundschreiben sehen. Deine nicht gesendeten Rundschreiben kannst du von hier aus bearbeiten, versenden oder neue Rundschreiben erstellen. ',
    heads: [
      { label: 'Datum', column: 'date' },
      { label: 'Titel', column: 'title' },
      { label: 'Status', column: 'status' },
    ],
    buttons: {
      create: {
        label: 'Rundschreiben verfassen',
        href: '/app/fanclub/rundschreiben/neues-rundschreiben/',
        icon: 'plus',
      },
      edit: {
        label: 'Entwurf',
        href: '/app/fanclub/rundschreiben',
      },
      sent: {
        label: 'Gesendet',
      },
    },
    filter: {
      all: 'Alle Rundschreiben',
      categories: [
        { uid: 1, title: 'Gesendet' },
        { uid: 2, title: 'Nicht gesendet' },
      ],
    },
    fallback: 'Du hast noch keine Rundschreiben angelegt.',
  },
  modals: {
    representative: {
      headline: {
        assigned: 'Betreuer nicht mehr zuordnen',
        unassigned: 'Betreuer zuordnen',
      },
      button: {
        assignRepresentative: 'Betreuer zuordnen',
        unassignRepresentative: 'Nicht mehr zuordnen',
      },
      description: 'Durch das Zuordnen zu diesem Betreuer stimmst du zu, dass der Betreuer dir per E-Mail Rundschreiben senden darf.',
    },
    journeys: {
      headline: 'Reise löschen',
      checkbox:
        'Stellen Sie bitte sicher, dass alle bereits angemeldeten Mitglieder über die Stornierung der Reise informiert sind, bevor Sie die Reise löschen. Mit einem Klick bestätigen Sie die zur Kenntnisnahme.',
      description: 'Bitte bestätige folgende Bedingung, um die Reise unwiderruflich zu löschen.',
      button: {
        label: 'Löschen',
      },
      input: {
        identifier: 'DFB-Fan-Club-Reise löschen',
        fieldIdentifier: 'delete',
        label:
          'Stellen Sie bitte sicher, dass alle bereits angemeldeten Mitglieder über die Stornierung der Reise informiert sind, bevor Sie die Reise löschen. Mit einem Klick bestätigen Sie die zur Kenntnisnahme.',
        error: 'Bitte bestätige die Bedingung, um die Reise zu löschen.',
      },
    },
    cancel: 'Abbrechen',
  },
  forms: {
    circular: {
      create: {
        stage: {
          label: 'Fan Club Rundschreiben',
          headline: 'Rundschreiben erstellen',
        },
        intro: {
          headline: 'Betreuer Rundschreiben',
          text: 'Hier kannst du ein neues Betreuer-Rundschreiben erstellen, dass deine zugeordneten Mitglieder per E-Mail empfangen.',
        },
        formfields: {
          headline: {
            label: 'Betreff',
          },
          text: {
            label: 'Text',
          },
          attachments: {
            label: 'Upload',
            headline: 'Anhänge',
            caption:
              'Hier kannst du zusätzlich Dateien hochladen, die den Mitgliedern zum Download bereit gestellt werden. Bitte beachte folgende Richtlinien: Format: PDF.',
          },
        },
        draft: {
          headline: 'Entwurf speichern',
          headlineUpdate: 'Entwurf aktualisieren',
          caption:
            'Du kannst deinen Entwurf speichern, um ihn später Zuende zu schreiben. Dein Rundschreiben wird noch nicht abgeschickt. Ein Abschicken ist erst möglich, wenn du alle Felder ausgefüllt hast und das Rundschreiben einmal gespeichert hast.',
          buttons: {
            save: 'Entwurf speichern',
            update: 'Entwurf aktualisieren',
            abort: 'Abbrechen',
            delete: 'Entwurf löschen',
          },
        },
        publish: {
          headline: 'Betreuerschreiben senden',
          caption:
            'Bevor du dein Betreuerschreiben versendest, kannst du dich vergewissern, ob alles passt. Hierzu kannst du dir selbst eine Testmail an deine E-Mail Adresse schicken, die in deinem Betreuerprofil hinterlegt ist. Mit dem Button “Rundschreiben senden” kannst du dein Rundschreiben final an deine zugeordneten Mitglieder senden.',
          buttons: {
            submit: 'An Mitglieder senden',
            test: 'Testmail an mich selbst',
          },
        },
        delete: {
          headline: 'Entwurf löschen',
          caption: 'Du kannst deinen Entwurf auch wieder löschen.',
          button: {
            label: 'Entwurf löschen',
          },
          modal: {
            headline: 'Entwurf löschen',
            caption: 'Durch "Entwurf löschen" löscht du deinen Entwurf und kehrst zur Übersichtsseite zurück.',
          },
        },
        abort: {
          button: {
            label: 'Entwurf abbrechen',
          },
          modal: {
            button: {
              label: 'Verwerfen',
            },
            headlineAbort: 'Änderungen verwerfen',
            headlineDiscard: 'Entwurf verwerfen',
            captionAbort:
              'Durch "Verwerfen" wird dein aktueller Entwurf des Rundschreibens nicht gespeichert. Du kehrst dann zur Übersichtsseite zurück.',
            captionDiscard:
              'Durch “Verwerfen” verwirfst du deine aktuellen Änderungen am Rundschreiben und es wird nichts gespeichert. Du kehrst dann zur Übersichtsseite zurück.',
          },
        },
      },
      backlink: {
        label: 'Zurück zur Übersicht',
        path: '/app/fanclub/rundschreiben',
      },
      success: {
        headlines: {
          sent: 'Deine Rundschreiben wurde erfolgreich verschickt!',
          update: 'Deine Aktualisierung wurde erfolgreich übermittelt!',
        },
        backlink: {
          label: 'Zurück zur Rundschreiben-Übersicht',
          path: '/app/fanclub/rundschreiben',
        },
        preview: {
          text: 'Deine Testmail wurde erfolgreich verschickt!',
        },
      },
    },
    journeys: {
      create: {
        stage: {
          label: 'Fan Club Reisen',
          headline: 'Fanclub Reise Erstellen',
        },
        intro: {
          headline: 'Betreuerreise erstellen',
          text: 'Du kannst eine Reise erstellen, die mit dir als Betreuer stattfindet. Alle Mitglieder des Fan Clubs können diese sehen und sich für die Reise bei dir per E-Mail anmelden.',
          headlineSmall: 'Allgemeine Infos zu der Reise',
        },
        formfields: {
          headline: {
            label: 'Überschrift',
            caption: 'Als Überschrift wird empfohlen den Anlass der Reise anzugeben, wie das Spiel (z.B. “Deutschland : Frankreich”).',
          },
          destination: {
            label: 'Reiseort',
            caption: 'Gibt hier den Reiseort an. (z.B. “Paris”).',
          },
          destinationDescription: {
            label: 'Kurzbeschreibung',
            caption: 'Hier kannst du die Reise beschreiben und deine Mitglieder so auf die Reise vorbereiten.',
          },
          keyData: {
            headline: 'Eckdaten der Reise',
            startDate: {
              label: 'Startdatum',
            },
            endDate: {
              label: 'Enddatum',
            },
            registration: {
              label: 'Anmeldeschluss',
              caption: 'Du kannst einen Anmeldeschluss angeben, der dir die Planung der Reise erleichtert.',
            },
            caption: 'Definiere das Start- und das Enddatum der Reise mithilfe der Kalendarfunktion.',
            costs: {
              label: 'Kosten',
              caption: 'Bitte gib die Kosten für die Reise pro Person an.',
            },
            departures: {
              label: 'Abfahrtsort(e)',
              caption: 'Gib hier einen Stadtnamen an. Sollten mehrere Abfahrtorte möglich sein, trenne diese bitte durch ein Komma.',
            },
            hotels: {
              label: 'Hotelübernachtungen',
              caption:
                'Gib hier Infos zum Hotelnamen etc. an, auch wenn es mehrere sind. Zudem kannst du die Hotels verlinken, damit die Mitglieder die Möglichkeit haben, sich die Hotels im Vorweg anzuschauen. ',
            },
          },
        },
        download: {
          headline: 'Download',
          caption:
            'Hier kannst du zusätzlich Dateien hochladen, die den Mitgliedern zum Download bereit gestellt werden. Bitte beachte folgende Richtlinien: Format: PDF, max. Anzahl an Dateien: 3.',
          textarea: {
            label: 'Beschreibung der Download-Dateien',
            caption: 'Falls notwendig, kannst du hier eine Beschreibung der Dateien einfügen.',
          },
        },
        preview: {
          headline: 'Vorschau',
          caption:
            'Hier kannst du die Reise vor dem Speichern noch einmal überprüfen. Dies ist nur möglich wenn alle Felder korrekt ausgefüllt sind.',
        },
        publish: {
          headline: 'Reise veröffentlichen',
        },
        buttons: {
          submit: 'Reise erstellen',
          update: 'Reise aktualisieren',
          cancel: 'Abbrechen',
          preview: 'Vorschau ansehen',
        },
      },
      backlink: {
        label: 'Zurück zur Übersicht',
        path: '/app/fanclub/reisen',
      },
      success: {
        headlines: {
          create: 'Deine Reise wurde erfolgreich erstellt!',
          update: 'Deine Reise wurde erfolgreich aktualisiert!',
          delete: 'Deine Reise wurde erfolgreich gelöscht!',
        },
        text: {
          create: 'Die Reise ist nun auf der Übersichtsseite aufgelistet. Dort kannst Du die Reise bearbeiten oder löschen.',
          update: 'Deine Änderungen wurden erfolgreich gespeichert. Du kannst nun zur Übersichtsseite zurückkehren.',
          delete: 'Die Reise wurde erfolgreich gelöscht. Du kannst nun zur Übersichtsseite zurückkehren.',
        },
      },
    },
    profile: {
      stage: {
        label: 'Fan Club Betreuer',
        headline: 'Betreuer-Profil bearbeiten',
      },
      headline: 'Dein Betreuerprofil',
      intro: {
        text: 'Dein Betreuerprofil kannst du hier editieren. Fan Club Mitglieder können dein Profil sehen.',
      },
      formfields: {
        headline: 'Personenbezogene Daten',
        label: {
          firstname: 'Vorname',
          lastname: 'Nachname',
          email: 'E-Mail',
          caption: 'Sie können Ihre persönlichen Daten nicht selbst ändern. Bitte wenden Sie sich an info@dfb.de',
          description: 'Profiltext',
          memberCount: 'Zugeordnete Mitglieder:',
        },
        error: {
          minLengthFive: 'Dein Text sollte eine Mindestlänge von 5 Zeichen haben.',
        },
      },
      button: {
        edit: {
          label: 'Profil bearbeiten',
        },
        back: {
          label: 'Zurück zum Profil',
          fallbackLabel: 'Zurück zur Betreuer-Übersicht',
          path: '/app/fanclub/betreuer',
        },
        submit: {
          label: 'Betreuerprofil aktualisieren',
        },
      },
      success: {
        headline: 'Dein Profil wurde erfolgreich aktualisiert!',
        backlink: {
          link: '/app/fanclub/betreuer/',
          text: 'Zurück zur Profil-Seite',
        },
      },
    },
    errors: {
      mandatory: 'Bitte fülle dieses Pflichtfeld aus.',
      priceNeedsUnit: 'Bitte gib den Preis mit Währungseinheit (z.B. €) an.',
      dateInPast: 'Das Datum darf nicht in Vergangenheit liegen.',
      endBeforeStart: 'Das Enddatum der Rundreise darf nicht vor dem Startdatum liegen!',
      bookableBeforeStart: "Das Datum 'Buchbar bis' darf nicht nach dem Startdatum liegen!",
      bookableBeforeEnd: "Das Datum 'Buchbar bis' darf nicht nach dem Enddatum liegen!",
      submit: 'Leider ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.',
    },
  },
}
